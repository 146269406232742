.contactPopupBackground {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.contactUsPopupContainer {
  z-index: 100;
  position: fixed;
  width: 80%;
  height: 100%;
  top: 0%;
  left: 10%;
  display: flex;
}

.contactUsPopupContainer .contactPopupCloseIcon {
  display: block !important;
  font-size: 26px;
  position: absolute;
  top: 2%;
  right: 1.5%;
  cursor: pointer;
  padding: 4px;
}

.contactUsPopupContainer #contactUs {
  margin: auto;
  position: relative;
}

.contactUsPopupContainer .contact-container {
  min-height: auto;
  padding: 30px;
  border-radius: 5px;
}

.contactUsPopupContainer .contact-container .contact-right-container {
  text-align: left;
}

.contactUsPopupContainer .contact-container .contact-right-container img {
  max-width: 120px;
}

.contactUsPopupContainer .contact-right-container .contact-designation {
  font-size: 14px;
  line-height: 18px;
  padding: 6px 12px;
}

.contactUsPopupContainer .contact-right-container .contactRight-listItems li {
  margin: 0 0 8px 10px;
  font-size: 16px;
}

@media only screen and (max-width: 800px) {
  .contactUsPopupContainer .contact-container .contact-right-container img {
    max-width: 100px;
  }

  .contactUsPopupContainer .contact-container {
    padding: 16px 22px;
  }

  .contactUsPopupContainer .contact-container .contact-left-container p {
    margin: 0 0 12px 0;
  }

  .contactUsPopupContainer .contactLeft-form form .inputForm-control {
    margin: 0 7.5px 6px 0;
  }
}

@media only screen and (max-width: 650px) {
  .contactUsPopupContainer .contact-container .contact-right-container img {
    max-width: 70px;
  }

  .contactUsPopupContainer .contact-right-container .contactRight-listItems li {
    margin: 0 0 4px 10px;
    font-size: 13px;
  }

  .contactUsPopupContainer .contactLeft-form form .inputForm-control {
    height: 30px;
    border-radius: 5px;
    border: solid 1px #d3d3d3;
    outline: none;
    background-color: var(--white);
  }

  .contactUsPopupContainer .contactLeft-form form .inputForm-textarea {
    padding: 8px 10px;
    height: 35px;
  }

  .contactUsPopupContainer .contactLeft-form form button {
    height: 30px;
    margin: 0 0 0 0 !important;
    width: 100%;
    padding: 7px 0;
    font-size: 14px;
  }

  .contactUsPopupContainer .contact-right-container .image-container {
    display: flex;
    justify-content: space-around;
  }

  .contactUsPopupContainer .contact-right-container .image-container div {
    flex: 0.5;
    height: 100%;
  }

  .contactUsPopupContainer .contact-right-container .contact-designation {
    height: fit-content;
    margin: auto 0;
    font-size: 12px;
    line-height: 16px;
    padding: 4px;
  }
}
