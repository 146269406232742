.contact-container {
  min-height: 100vh;
  display: flex;
  padding: 91px 117px;
  background: var(--p3BgColor);
}

.contact-left-container {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  font-family: "Josefin Sans", sans-serif;
  justify-content: center;
  margin-right: 15px;
}

.contact-container .contact-left-container h2 {
  font-size: 44px;
  text-align: left;
  font-weight: 400;
  letter-spacing: 1.76px;
  margin: 0 36.7px 10.9px 0;
  font-family: "Passion One", cursive;
  color: #333;
}

.contact-container .contact-left-container p {
  font-size: 20px;
  text-align: left;
  margin: 0 0 23.5px 0;
  color: #555;
  max-width: 731px;
}

.contactLeft-form form {
  max-width: 650px;
}

.contactLeft-form form input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.contactLeft-form form ::placeholder {
  font-family: "Josefin Sans", sans-serif;
  font-size: 14px;
  color: #707070;
}

.contactLeft-form form .inputForm-control {
  min-width: 250px;
  max-width: 307px;
  width: 100%;
  height: 44.5px;
  margin: 0 7.5px 16.4px 0;
  padding: 15px 16px;
  border-radius: 5px;
  border: solid 1px #d3d3d3;
  outline: none;
  background-color: var(--white);
}

.contactLeft-form form .inputForm-textarea {
  max-width: 622.3px;
  width: 100%;
  height: 67px;
  resize: none;
}

.contactLeft-form form button {
  max-width: 307px;
  width: 100%;
  height: 44.5px;
  margin: 0 7.5px 0 0;
  padding: 14px 0;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #43adc4;
  font-size: 18px;
  text-align: center;
  color: var(--white);
  cursor: pointer;
}

.contactLeft-form form .contact-errorMessage {
  font-size: 12px;
  margin: 0;
  width: fit-content;
  color: red;
}

.contact-right-container {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-right-container .image-container {
  text-align: center;
}

.contact-right-container img {
  max-width: 330px;
}

.contact-right-container .contact-designation {
  font-size: 16px;
  background-color: #43adc4;
  color: var(--white);
  border-radius: 5px;
  line-height: 22px;
  width: fit-content;
  margin: 4px auto 10px;
  padding: 8px 16px;
}

.contact-right-container .contactRight-listItems li {
  margin: 0 0 15px 11px;
  font-size: 18px;
  color: #555;
  font-family: "Josefin Sans", sans-serif;
}

.contact-right-container .contactRight-listItems li::before {
  content: "● ";
  color: green;
  font-weight: bold;
  margin-right: 6px;
  display: inline-block;
  /* width: 1em; */
}

@media only screen and (max-width: 1202px) {
  .contactLeft-form form .inputForm-textarea {
    max-width: 307px;
    width: 100%;
  }
}

@media only screen and (max-width: 1100px) {
  .contact-container {
    padding: 30px 80px;
  }

  .contact-container .contact-left-container h2 {
    font-size: 36px;
  }

  .contact-container .contact-left-container p,
  .contact-right-container .contactRight-listItems li {
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .contact-container {
    padding: 15px 30px;
  }

  .contact-right-container img {
    max-width: 130px;
  }

  .contact-right-container .contact-designation {
    font-size: 14px;
  }

  .contact-right-container .contactRight-listItems li {
    font-size: 14px;
  }
}

@media only screen and (max-width: 650px) {
  .contact-container {
    padding: 23px;
    flex-direction: column;
  }

  .contact-left-container {
    display: block;
    flex: 0;
  }

  .contact-container .contact-left-container h2 {
    font-size: 25px;
    letter-spacing: 1px;
    margin: 0 0 7.9px 0;
  }

  .contact-container .contact-left-container p {
    font-size: 14px;
    margin: 0 0 13.1px 0;
  }

  .contact-right-container {
    text-align: center;
    display: block;
    flex: 0;
  }

  .contact-right-container img {
    max-width: 100px;
  }

  .contact-right-container .contact-designation {
    font-size: 12px;
  }

  .contactRight-listItems {
    display: flex;
    justify-content: center;
  }

  .contact-right-container .contactRight-listItems ul {
    list-style: outside;
  }

  .contact-right-container .contactRight-listItems li {
    font-size: 14px;
    text-align: left;
    margin: 0 7.5px 8px 8.8px;
  }

  .contact-right-container .contactRight-listItems li::before {
    content: "";
    margin: 0;
    width: 0;
  }

  .contactLeft-form form {
    margin: 10px auto 0;
    width: 90%;
  }

  .contactLeft-form form .inputForm-control {
    margin: 0 0 12.4px;
    width: 100%;
    max-width: none;
  }

  .contactLeft-form form button {
    width: 100%;
    max-width: none;
  }
}
