@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Rubik:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Passion+One&display=swap);
header {
  display: flex;
  justify-content: space-between;
  height: var(--headerHeight);
  align-items: center;
  font-size: 15px;
  padding: 0 20px;
  font-family: "Josefin Sans";
  position: absolute;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  border-bottom: 1px solid #f5f5f5;
}

.new__blog__header {
  position: unset;
  background: var(--b2LinearGradiant);
}

.header__shadow {
  background-color: white;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
}

.left__header {
  flex: 0.2 1;
}

.logo__container img {
  width: 133px;
}

.right__header {
  flex: 0.6 1;
}

.nav__list {
  display: flex;
  justify-content: space-evenly;
}

.nav__list .list__items {
  position: relative;
}

.nav__list .list__items::after {
  content: "";
  height: 2px;
  background: var(--blue);
  position: absolute;
  width: 0%;
  bottom: -3px;
  left: 0;
  transition: all 0.2s ease-out;
  opacity: 0;
}

.nav__list .list__items::before {
  content: "";
  height: 2px;
  background: var(--blue);
  position: absolute;
  width: 0%;
  bottom: -3px;
  right: 0;
  transition: all 0.2s ease-out;
  opacity: 0;
}

.right__header__mobile .nav__list .list__items::after,
.right__header__mobile .nav__list .list__items::before {
  background: var(--blue);
}

.nav__list .list__items a {
  color: var(--black);
  font-weight: var(--medium);
  transition: color 0.2s linear;
  display: inline-block;
  width: 100%;
  font-size: 18px;
}

.nav__list .list__items:hover::after,
.nav__list .list__items:hover::before {
  width: 50%;
  opacity: 1;
}

.nav__list .list__items:hover a {
  color: var(--blue);
}

.navbar__overlay {
  display: none;
}
.hamburger {
  display: none;
}

.casestudies__header {
  background: var(--white);
  position: unset;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.07);
}

.casestudies__header .nav__list .list__items a {
  color: var(--black);
}

.casestudies__header .nav__list .list__items a:hover {
  color: var(--blue);
}

/* responseive view starts */

@media screen and (max-width: 1100px) {
  .nav__list .list__items a {
    font-size: 16px;
  }
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .right__header__mobile {
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    background-color: rgba(255, 255, 255, 0.5);
  }
}
@media screen and (max-width: 992px) {
  header {
    --headerHeight: 80px;
    padding: 0 30px;
  }
  .right__header {
    display: none;
  }
  .hamburger {
    display: block;
  }
  .navbar__overlay {
    width: 100%;
    background: transparent;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    transition: all 0.3s ease-out;
    z-index: 99;
    display: block;
  }
  .right__header__mobile {
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 100%;
    z-index: 99999;
    position: absolute;
    right: 0;
  }
  .right__header__mobile .nav__list .list__items a {
    color: var(--black);
    font-size: 18px;
  }
  .right__header__mobile .nav__list .list__items a:hover {
    color: var(--blue);
    font-size: 18px;
  }
  .right__header__mobile .nav__list .list__items a.active {
    color: var(--blue);
  }
  .hide__navbar {
    right: -100%;
  }
  .right__header__mobile nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
  }
  .close__navbar {
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    color: var(--blue);
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 20px;
    box-shadow: 0px 3px 6px 0px rgba(255, 255, 255, 0.1);
    margin-bottom: 30px;
  }
  .close__navbar img {
    margin-right: 10px;
    width: 14px;
    height: auto;
  }
  .social__icons__navbar {
    margin-bottom: 20px;
  }
  .social__icons__navbar img {
    width: 27px;
    height: auto;
  }
  .social__icons__navbar a:not(:last-child) {
    margin-right: 30px;
  }
  .right__header__mobile .nav__list {
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    max-height: 500px;
  }
  .right__header__mobile .nav__list .list__items {
    font-size: 18px;
  }
  .back__navigation__container {
    height: 60px;
    width: 100%;
    background-image: linear-gradient(to right, #7d60eb, #e08aff);
    padding-left: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .right__header__mobile::-webkit-scrollbar {
    display: none;
  }
  .logo__container img {
    width: 100px;
  }
}

@media screen and (max-width: 480px) {
  .logo__container img {
    width: 90px;
  }
  header {
    --headerHeight: 70px;
    padding: 0 1.5em;
  }
  .logo__container {
    margin: 0;
  }
}
/* responseive view ends */

/* arrow buttons */

.arrow-btn-left {
  position: absolute;
  background-color: #ffffff5d; 
  border: none;
  cursor: pointer;
  top: 50%;
  left: 0;
  width: 32px;
  height: 44px;
  overflow: hidden;
  z-index: 9;
  border-radius: 0 8px 8px 0;
}

.arrow-btn-right {
  position: absolute;
  background-color: #ffffff5d; 
  border: none;
  cursor: pointer;
  top: 50%;
  right: 0;
  width: 32px;
  height: 44px;
  overflow: hidden;
  z-index: 9;
  border-radius: 8px 0 0 8px;

}


/**/



.banner-container {
  min-height: 100vh;
  padding-top: 100px;
  display: flex;
}

.banner-container .bannerPage2,
.banner-container .bannerPage4 {
  flex-direction: row-reverse;
}

.banner-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.banner-container .banner-content a {
  text-decoration: none;
  color: #fff;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.bannerPage1,
.bannerPage3 {
  padding-left: 84px;
}

.banner-content .left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bannerPage1 .left-container {
  max-width: 437px;
}

.bannerPage2 .left-container {
  margin: 0 auto 0 40px;
  max-width: 421px;
}

.bannerPage3 .left-container {
  max-width: 700px;
}

.bannerPage4 .left-container {
  margin: 0 auto 0 40px;
  max-width: 540px;
}

.bannerPage2 .left-container ul {
  text-align: left;
  margin-bottom: 21px;
}

.bannerPage2 .left-container li::before {
  content: "• ";
  color: green;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.banner-content .main-heading {
  font-size: 47px;
  text-align: left;
  line-height: 53px;
  letter-spacing: 1.5px;
  margin: 0 0 11.6px;
  font-family: Rubik;
  font-weight: bold;
  color: #333;
}

.banner-content .left-container p {
  font-size: 22px;
  text-align: left;
  line-height: 30px;
  margin: 11.6px 0 26.3px;
  letter-spacing: normal;
  color: #333;
}

.banner-content .left-container button {
  border: 1px solid #3991a5;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 13px 21px 12.6px 22px;
  border-radius: 5px;
  background-color: #3991a5;
  font-size: 18px;
  text-align: center;
  color: var(--white);
}

.banner-content .left-container button:hover {
  background-color: var(--white);
  color: #3991a5;
  transition: all 0.2s linear;
}

.banner-content .right-container {
  margin: auto 0;
}

.bannerPage1 .right-container,
.bannerPage3 .right-container {
  margin-left: 20px;
  margin-right: 20px;
}

.bannerPage2 .right-container,
.bannerPage4 .right-container {
  margin-left: 30px;
}

.bannerPage4 .right-container {
  position: relative;
}

.bannerPage4 .right-container .rightContainer-InnerImg {
  position: absolute;
  left: 0;
}

.banner-content .right-container img {
  max-width: 700px;
  max-height: 647px;
  object-fit: contain;
}

.bannerPage3 .right-container img {
  max-height: 600px;
}

.bannerPage4 .right-container img {
  height: 627px;
}

.bannerPage3 .right-container .right-container-content,
.bannerPage2 .right-container .right-container-content {
  position: relative;
}

.bannerPage3 .right-container .designation-container,
.bannerPage2 .right-container .designation-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 42px;
  background: var(--white);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 34px 10.2px;
  border-radius: 33.5px;
  font-family: "Josefin Sans", sans-serif;
  color: #333;
}

.bannerPage3 .right-container .designation-container .desigName {
  font-size: 20px;
  margin-bottom: 6px;
  font-weight: 600;
}

.bannerPage3 .right-container .designation-container .desigPara {
  font-size: 16px;
}

.bannerPage2 .designation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 6px 10.2px !important;
}

.bannerPage2 .designation-container .techStacks {
  border-radius: 20px;
  background-color: #f5f5f5;
  margin: 0 11px 0 11px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerPage2 .designation-container .moreTechStack {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  background-color: #3991a5;
  color: var(--white);
}

.bannerPage2 .designation-container .techStacks .techStackIcons {
  margin: 0 10px 0 0;
  font-size: 24px;
}

.bannerPage2 .designation-container .moreTechStack .techStackIcons {
  margin-left: 10px !important;
}

@media only screen and (max-width: 1100px) {
  .banner-container {
    height: 100%;
  }

  .bannerPage1,
  .bannerPage3 {
    padding-left: 50px;
  }

  .banner-content .main-heading {
    font-size: 38px;
    line-height: 50px;
    margin: 0 0 11.6px;
  }

  .bannerPage1 .left-container {
    max-width: 350px;
  }

  .bannerPage3 .left-container {
    max-width: 600px;
  }

  .bannerPage4 .left-container {
    max-width: 450px;
  }

  .banner-content .left-container p {
    font-size: 18px;
    line-height: 24px;
    margin: 11.6px 0 26.3px;
  }

  .banner-content .left-container button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 38px;
    padding: 13px 21px 12.6px 22px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .bannerPage1,
  .bannerPage3 {
    padding-left: 35px;
  }

  .banner-content .main-heading {
    font-size: 32px;
    line-height: 40px;
  }

  .bannerPage1 .left-container {
    max-width: 340px;
  }

  .bannerPage2 .left-container,
  .bannerPage4 .left-container {
    margin: 0 auto 0 20px;
    min-width: 290px;
  }

  .banner-content .left-container p {
    font-size: 18px;
    line-height: 24px;
    margin: 8px 0 20px;
  }

  .banner-content .left-container button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 35px;
    padding: 11px 16px 12.6px 15px;
    font-size: 16px;
  }

  .bannerPage1 .right-container {
    margin-left: 20px;
  }

  .bannerPage3 .right-container .designation-container {
    padding: 6px 25px;
    bottom: 20px;
  }

  .bannerPage3 .right-container .designation-container .desigName {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .bannerPage3 .right-container .designation-container .desigPara {
    font-size: 14px;
  }

  .bannerPage2 .right-container .designation-container {
    padding: 6px 8px 6px !important;
  }

  .bannerPage2 .designation-container .techStacks {
    font-size: 14px;
    margin: 0 6px 0 6px;
  }

  .bannerPage2 .designation-container .techStacks .techStackIcons {
    margin: 0 4px 0 0;
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {

  .banner-container .bannerPage2,
  .banner-container .bannerPage4 {
    flex-direction: column-reverse;
  }

  .banner-content {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
  }

  .banner-container .banner-content a {
    margin-bottom: 26px;
  }

  .bannerPage2 .left-container {
    max-width: 320px;
  }

  .bannerPage3 .left-container {
    max-width: 400px;
  }

  .banner-content .left-container {
    margin: 0 80px 0 0;
    width: 100%;
  }

  .bannerPage3 .left-container {
    margin: 0 40px 0 0;
  }

  .bannerPage4 .left-container {
    max-width: 400px;
    margin: 0 40px 0 0;
  }

  .banner-content .right-container {
    margin: 0 0 25px 0;
    width: 100%;
  }

  .bannerPage4 .right-container .rightContainer-InnerImg {
    right: 0;
  }

  .banner-content .right-container img {
    max-width: 550px;
    padding: 0 35px;
    height: 100%;
    margin: 0 auto;
  }

  .bannerPage2 .right-container .designation-container {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .banner-content .main-heading {
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.8px;
    margin: 24px 10px 11.1px 9.7px;
  }

  .banner-content .left-container {
    margin: 0;
    padding: 0 30px;
    max-width: 100%;
  }

  .banner-content .left-container p {
    margin: 0 10px 24px 9.7px;
    font-size: 16px;
    line-height: 22px;
    max-width: 300px;
  }

  .bannerPage3 .left-container p {
    margin: 0 10px 10px 9.7px;
  }

  .bannerPage2 .left-container ul {
    margin-left: 16px;
  }

  .banner-content .right-container {
    margin: 0 0 0 0;
    width: 100%;
  }

  .banner-content .left-container button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 41px;
    /* margin: 0 49.1px 141.3px 9.7px; */
    padding: 12px 20.9px 12.6px 20px;
    font-size: 16px;
  }
}

.contact-container {
  min-height: 100vh;
  display: flex;
  padding: 91px 117px;
  background: var(--p3BgColor);
}

.contact-left-container {
  flex: 0.7 1;
  display: flex;
  flex-direction: column;
  font-family: "Josefin Sans", sans-serif;
  justify-content: center;
  margin-right: 15px;
}

.contact-container .contact-left-container h2 {
  font-size: 44px;
  text-align: left;
  font-weight: 400;
  letter-spacing: 1.76px;
  margin: 0 36.7px 10.9px 0;
  font-family: "Passion One", cursive;
  color: #333;
}

.contact-container .contact-left-container p {
  font-size: 20px;
  text-align: left;
  margin: 0 0 23.5px 0;
  color: #555;
  max-width: 731px;
}

.contactLeft-form form {
  max-width: 650px;
}

.contactLeft-form form input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.contactLeft-form form ::placeholder {
  font-family: "Josefin Sans", sans-serif;
  font-size: 14px;
  color: #707070;
}

.contactLeft-form form .inputForm-control {
  min-width: 250px;
  max-width: 307px;
  width: 100%;
  height: 44.5px;
  margin: 0 7.5px 16.4px 0;
  padding: 15px 16px;
  border-radius: 5px;
  border: solid 1px #d3d3d3;
  outline: none;
  background-color: var(--white);
}

.contactLeft-form form .inputForm-textarea {
  max-width: 622.3px;
  width: 100%;
  height: 67px;
  resize: none;
}

.contactLeft-form form button {
  max-width: 307px;
  width: 100%;
  height: 44.5px;
  margin: 0 7.5px 0 0;
  padding: 14px 0;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #43adc4;
  font-size: 18px;
  text-align: center;
  color: var(--white);
  cursor: pointer;
}

.contactLeft-form form .contact-errorMessage {
  font-size: 12px;
  margin: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: red;
}

.contact-right-container {
  flex: 0.3 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-right-container .image-container {
  text-align: center;
}

.contact-right-container img {
  max-width: 330px;
}

.contact-right-container .contact-designation {
  font-size: 16px;
  background-color: #43adc4;
  color: var(--white);
  border-radius: 5px;
  line-height: 22px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 4px auto 10px;
  padding: 8px 16px;
}

.contact-right-container .contactRight-listItems li {
  margin: 0 0 15px 11px;
  font-size: 18px;
  color: #555;
  font-family: "Josefin Sans", sans-serif;
}

.contact-right-container .contactRight-listItems li::before {
  content: "● ";
  color: green;
  font-weight: bold;
  margin-right: 6px;
  display: inline-block;
  /* width: 1em; */
}

@media only screen and (max-width: 1202px) {
  .contactLeft-form form .inputForm-textarea {
    max-width: 307px;
    width: 100%;
  }
}

@media only screen and (max-width: 1100px) {
  .contact-container {
    padding: 30px 80px;
  }

  .contact-container .contact-left-container h2 {
    font-size: 36px;
  }

  .contact-container .contact-left-container p,
  .contact-right-container .contactRight-listItems li {
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .contact-container {
    padding: 15px 30px;
  }

  .contact-right-container img {
    max-width: 130px;
  }

  .contact-right-container .contact-designation {
    font-size: 14px;
  }

  .contact-right-container .contactRight-listItems li {
    font-size: 14px;
  }
}

@media only screen and (max-width: 650px) {
  .contact-container {
    padding: 23px;
    flex-direction: column;
  }

  .contact-left-container {
    display: block;
    flex: 0 1;
  }

  .contact-container .contact-left-container h2 {
    font-size: 25px;
    letter-spacing: 1px;
    margin: 0 0 7.9px 0;
  }

  .contact-container .contact-left-container p {
    font-size: 14px;
    margin: 0 0 13.1px 0;
  }

  .contact-right-container {
    text-align: center;
    display: block;
    flex: 0 1;
  }

  .contact-right-container img {
    max-width: 100px;
  }

  .contact-right-container .contact-designation {
    font-size: 12px;
  }

  .contactRight-listItems {
    display: flex;
    justify-content: center;
  }

  .contact-right-container .contactRight-listItems ul {
    list-style: outside;
  }

  .contact-right-container .contactRight-listItems li {
    font-size: 14px;
    text-align: left;
    margin: 0 7.5px 8px 8.8px;
  }

  .contact-right-container .contactRight-listItems li::before {
    content: "";
    margin: 0;
    width: 0;
  }

  .contactLeft-form form {
    margin: 10px auto 0;
    width: 90%;
  }

  .contactLeft-form form .inputForm-control {
    margin: 0 0 12.4px;
    width: 100%;
    max-width: none;
  }

  .contactLeft-form form button {
    width: 100%;
    max-width: none;
  }
}

.contactPopupBackground {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.contactUsPopupContainer {
  z-index: 100;
  position: fixed;
  width: 80%;
  height: 100%;
  top: 0%;
  left: 10%;
  display: flex;
}

.contactUsPopupContainer .contactPopupCloseIcon {
  display: block !important;
  font-size: 26px;
  position: absolute;
  top: 2%;
  right: 1.5%;
  cursor: pointer;
  padding: 4px;
}

.contactUsPopupContainer #contactUs {
  margin: auto;
  position: relative;
}

.contactUsPopupContainer .contact-container {
  min-height: auto;
  padding: 30px;
  border-radius: 5px;
}

.contactUsPopupContainer .contact-container .contact-right-container {
  text-align: left;
}

.contactUsPopupContainer .contact-container .contact-right-container img {
  max-width: 120px;
}

.contactUsPopupContainer .contact-right-container .contact-designation {
  font-size: 14px;
  line-height: 18px;
  padding: 6px 12px;
}

.contactUsPopupContainer .contact-right-container .contactRight-listItems li {
  margin: 0 0 8px 10px;
  font-size: 16px;
}

@media only screen and (max-width: 800px) {
  .contactUsPopupContainer .contact-container .contact-right-container img {
    max-width: 100px;
  }

  .contactUsPopupContainer .contact-container {
    padding: 16px 22px;
  }

  .contactUsPopupContainer .contact-container .contact-left-container p {
    margin: 0 0 12px 0;
  }

  .contactUsPopupContainer .contactLeft-form form .inputForm-control {
    margin: 0 7.5px 6px 0;
  }
}

@media only screen and (max-width: 650px) {
  .contactUsPopupContainer .contact-container .contact-right-container img {
    max-width: 70px;
  }

  .contactUsPopupContainer .contact-right-container .contactRight-listItems li {
    margin: 0 0 4px 10px;
    font-size: 13px;
  }

  .contactUsPopupContainer .contactLeft-form form .inputForm-control {
    height: 30px;
    border-radius: 5px;
    border: solid 1px #d3d3d3;
    outline: none;
    background-color: var(--white);
  }

  .contactUsPopupContainer .contactLeft-form form .inputForm-textarea {
    padding: 8px 10px;
    height: 35px;
  }

  .contactUsPopupContainer .contactLeft-form form button {
    height: 30px;
    margin: 0 0 0 0 !important;
    width: 100%;
    padding: 7px 0;
    font-size: 14px;
  }

  .contactUsPopupContainer .contact-right-container .image-container {
    display: flex;
    justify-content: space-around;
  }

  .contactUsPopupContainer .contact-right-container .image-container div {
    flex: 0.5 1;
    height: 100%;
  }

  .contactUsPopupContainer .contact-right-container .contact-designation {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto 0;
    font-size: 12px;
    line-height: 16px;
    padding: 4px;
  }
}

.values__container{
    font-family: 'Josefin Sans';
    display: flex;
    height:100vh;
}

.left__values__container{
    flex:0.6 1;
    background: url(/static/media/bg2.0ddaa749.png) no-repeat center center/contain;
    display: grid;
    place-items: center;
}

.values__card{
    display: flex;
}

.value__card{
    height:165px;
    width:165px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background: var(--white);
    align-items: center;
    justify-content: space-evenly;
    margin:1em 2.5em;
    box-shadow: 0px 8px 16px 0px #e8e8e8;
    transition:all 150ms linear;
    animation:fadeInValues 0.3s 1 ease-in;
}

@keyframes fadeInValues{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.value__card:hover{
    transform: scale(1.05);
}

.value__card .value__icon img{
    max-width: 80px;
}

.values__card .row1 .value__card:nth-child(1){
    margin-top:0px;
}

.values__card .row1 .value__card:nth-child(2){
    margin-top:80px;
}

.values__card .row2 .value__card:nth-child(1){
    margin-top:60px;
}

.values__card .row2 .value__card:nth-child(2){
    margin-top:80px;
}

.value__title{
    color:var(--lightBlack);
    font-size:18px;
}

.right__values__container{
    flex:0.4 1;
    padding:5em 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.right__values__container .main__heading{
    margin-bottom:25px;
    text-align: left;
}

.right__values__container .secondary__heading p{
    margin:0.8em 0;
    font-size:20px;
    color:#505050;
}

.right__values__container .secondary__heading p:hover > .underline{
    color:var(--blue);
} 

/* responseive view starts */

@media screen and (max-width:900px){
    .values__container{
        padding:2em;
    }
    .right__values__container{
        padding:5em 0em;
    }
    .right__values__container .main__heading{
        margin-bottom:30px;
    }
    .right__values__container .secondary__heading p{
        margin:0.5em 0;
        font-size:22px;
    }
    .value__card{
        margin:1em 1.5em;
    }
    .values__card .row1 .value__card:nth-child(1){
        margin-top:0px;
    }
    .values__card .row1 .value__card:nth-child(2){
        margin-top:80px;
    }
    .values__card .row2 .value__card:nth-child(1){
        margin-top:60px;
    }
    .values__card .row2 .value__card:nth-child(2){
        margin-top:80px;
    }
}

@media screen and (max-width:900px){
    .value__card{
        height:140px;
        width:140px;
    }
    .value__card .value__icon img{
        max-width: 72px;
    }
}

@media screen and (max-width:768px){
    .values__container{
        height: unset;
        padding:40px 0;
        flex-direction: column-reverse;
    }
    .left__values__container{
        background-repeat: no-repeat;
        background-size:contain;
        min-height: 400px;
    }
    .right__values__container{
        padding:1em;
    }
    .value__card{
        height:112px;
        width:112px;
    }
}

@media screen and (max-width:480px){
    .right__values__container .secondary__heading p{
        font-size:16px;
    }
    .right__values__container .main__heading{
        margin-bottom:20px;
    }
    .value__title{
        font-size:13px;
    }   
    .values__card .row1 .value__card:nth-child(1){
        margin-top:20px;
    }
    .values__card .row1 .value__card:nth-child(2){
        margin-top:40px;
    }
    .values__card .row2 .value__card:nth-child(1){
        margin-top:40px;
    }
    .values__card .row2 .value__card:nth-child(2){
        margin-top:40px;
    }
    .value__card .value__icon img{
        max-width: 55px;
    }
    .value__card{
        height:100px;
        width:100px;
    }
}

@media screen and (max-width:370px){
    
    .value__card .value__icon img{
        max-width: 68px;
    }
}
/* responseive view ends */
.technologies__container{
    padding:75px 100px;
    font-family: 'Josefin Sans';
    background:var(--bgGray);
}

.technologies__heading{
    margin-bottom:75px;
}

.technologies__container .secondary__heading{
    margin-top: 20px;
    text-align: center;
    color:var(--lightBlack);
}

.technologies__cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.technology__title{
    font-size:16px;
    color:var(--lightBlack);
    text-align: center;
}

.technology__card{
    height:130px;
    width:130px;
    border-radius: 15px;
    box-shadow: 0px 3px 15px 0px #e8e8e8e8;
    display: flex;
    background:var(--white);
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin:2em;
    animation:fadeIn 0.3s 1 ease-in;
}

@keyframes fadeIn{
    from{
        transform:translateY(40px) ;
        opacity:0;
    }to{
        transform:translateY(0px) ;
        opacity:1;
    }
}

.technology__card img{
    height:100%;
    width:100%;
    object-fit: contain;
}

.technology__icon{
    max-width:90px;
    max-height:50px;
}

.technologies__rail__container{
    margin-bottom: 60px;
}

.technologies__rail__list{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color:var(--lightBlack);
}

.technologies__rail__list > li{
    transition:all 0.2s linear;
    padding-bottom:1px;
    border-bottom:2px solid transparent;
    cursor: pointer;
}

.technologies__rail__list > li.active{
    color:var(--blue);
    border-bottom:2px solid var(--blue);
    font-weight:var(--bold);
}

/* responseive view starts */

@media screen and (max-width:900px){
    .technologies__container{
        padding:2em;
    }
    .technologies__heading{
        margin-bottom:2em;
        margin-left: 0;
    }
    .technology__card{
        margin:1em;
        box-shadow: 0px 0px 25px #e8e8e8e8;
    }
    .technologies__rail__list{
        justify-content: center;
    }
    .technologies__rail__list > li{
        margin:20px;
    }
}

@media screen and (max-width:600px){
    .technology__card{
        height:100px;
        width:100px;
        margin:0.8em;
        box-shadow: 0px 0px 15px #e8e8e8e8;
    }
    .technology__icon{
        max-width:70px;
        max-height:30px;
    }
    .technology__title{
        font-size: 12px;
    }
    .technologies__rail__list > li{
        margin:10px;
        font-size: 12px;
    }
}

@media screen and (max-width:480px){
    .technologies__container{
        padding:40px 1em;
    }
    .technologies__container .secondary__heading{
        margin-top: 15px;
    }
    .technology__title{
        font-size:13px;
    }
    .technology__card{
        height:100px;
        width:100px;
        margin:0.5em;
        box-shadow: 0px 0px 15px #e8e8e8e8;
        border-radius: 7px;
    }
    .technology__icon{
        max-width:70px;
        max-height:30px;
    }
    .technologies__rail__container{
        margin-bottom: 30px;
    }
}
@media screen and (max-width:380px){
    .technology__title{
        font-size:12px;
    }
    .technology__card{
        height:80px;
        width:80px;
        margin:0.5em;
        box-shadow: 0px 0px 15px #e8e8e8e8;
    }
    .technology__icon{
        max-width:60px;
        max-height:25px;
    }
}
/* responseive view ends */
.testimonials__container{
    padding:4em;
    font-family: 'Josefin Sans';
}

.testimonials__container .main__heading{
    margin-bottom: 21px;
}

.testimonials__container .secondary__heading{
    text-align: center;
}

.testimonials__heading{
    margin-bottom: 50px;
    margin-left:30px;
}

.testimonial__group{
    display: flex;
    justify-content: center;
}

.testimonial__card{
    background:white;
    box-shadow: 0px 3px 59px 0px #e8e8e8e8;
    border-radius:15px;
    padding:2em;
    padding-bottom:1.5em;
    margin:2em;
    text-align: left;
    max-width:500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.testimonial__content p{
    color:var(--lightBlack);
    line-height:29px;
    padding:0.2em 1.5em;
    font-size: 20px;
}

.right__quote{
    text-align: right;
}

.testimonial__footer{
    font-size: 20px;
    margin-top:20px;
    text-align: right;
}

.testimonial__role{
    font-size:14px;
    color:var(--black);
}

.carousel .slide{
    background-color: unset;
}

.testimonials__container .carousel .slider-wrapper{
    box-shadow: unset;
}

.testimonials__container .carousel .slider-wrapper.axis-horizontal .slider .slide{
    background: var(--white);
}
/* responsive view starts */

@media screen and (max-width:900px){
    .testimonials__container{
        padding:50px 2em;
    }
    .testimonial__card{
        margin:1em;
        padding:1em;
    }
    .testimonial__content p{
        padding:0.2em 1em;
    }   
    .testimonial__role{
        font-size:12px;
    } 
    .testimonials__container .carousel .control-dots{
        bottom:-10px;
    }
}

@media screen and (max-width:480px){
    .testimonials__container .secondary__heading{
        text-align: left;
    }
    .testimonials__container .main__heading{
        margin-bottom: 15px;
    }
    .testimonials__heading{
        margin:0;
        margin-bottom: 30px;
    }
    .testimonial__content p{
        font-size: 16px;
        line-height: 25px;
    }
    .testimonial__footer{
        font-size: 16px;;
    }
    .testimonial__role{
        font-size:10px;
    } 
    .testimonial__card{
        margin: 1em;
    }
    .carousel.carousel-slider{
        padding:0;
    }
}

@media screen and (max-width:375px){
    .testimonials__container{
        padding:50px 0.5em;
    }
    .testimonials__heading{
        margin-left:10px;
    }
    .testimonial__content p{
        font-size: 15px;
    }
    .testimonial__footer{
        font-size: 15px;
    }
}


/* responsive view ends */
footer{
    background:var(--blue);
    color:var(--white);
    font-family: 'Josefin Sans';
}
.footer__row1{
    padding:3em 4em;
    display: flex;
    justify-content: space-between;
}

.footer__column1{
    flex:0.4 1;
    min-width:350px;
}

.footer__column2{
    flex:0.2 1;
}

.footer__column3{
    flex:0.2 1;
}

.footer__heading{
    font-size:35px;
    margin-bottom:0.8em;
    font-family: 'Passion One';
    letter-spacing: 0.5px;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.footer__content{
    font-size:16px;
    line-height:26px;
}

.footer__locate__row1,
.footer__locate__row2,
.footer__locate__row3
{
    margin-bottom:10px;
    display: flex;
}

.footer__row2{
    background:var(--darkBlue);
    text-align: center;
    padding:1em 0;
    font-size:18px;
}

.footer__row2 span{
    margin:0 3px;
}

.footer__locate__row1 img{
    width:15px;
    height:22px;
    margin-top:5px;
    margin-right:10px;
}

.footer__locate__row2 img{
    width:20px;
    height:16px;
    object-fit: contain;
    margin-right:10px;
    margin-top:5px;
}

.footer__locate__row3 img{
    width:20px;
    height:20px;
    margin-right:10px;
    margin-top:5px;
}

.footer__column3 .footer__content img{
    height:24px;
    width:24px;
}

.footer__column3 .footer__content a:not(:nth-child(3)) img{
    margin-right: 20px;
}

.location__text a{
    color:var(--white)
}

.motto{
    display: block;
    margin-bottom:10px;
    font-style: italic;
    font-size:18px;
    color:white;
}


@media screen and (max-width:920px){
    .footer__column1{
        min-width:unset;
    }
    .footer__row1{
        padding:3em 3em;
        display: flex;
        flex-direction: column;
    }
    .footer__column1,.footer__column2{
        margin-bottom:30px;
    }
    .footer__heading{
        margin-bottom:10px;
    }
}

@media screen and (max-width:768px){
    .footer__row1{
        padding:2em 1em;
    }
}

@media screen and  (max-width:480px){
    .arr{
        display: block;
        margin-top:4px;
    }
    .line{
        display: none;
    }
    .motto{
        font-size:16px;
    }
    .footer__heading{
        font-size: 25px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width:350px){
    .footer__row1{
        padding:25px 1em;
    }
}
.login__wrapper{
    height:100vh;
    width:100vw;
    display: grid;
    place-items: center;
    background: #efefef;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
}

.login__container{
    width:500px;
    background:white;
    border-radius:15px;
    padding:30px;
}

.login__container h3{
    font-size:35px;
    text-align: center;
    margin-bottom: 30px;
}

.error{
    display: block;
    color:tomato;
    font-size:14px;
}

form{
    width:100%;
    margin: 0;
}

.form__group{
    width:100%;
    margin:20px 0;
}

label{
    display: inline-block;
    margin-bottom: 5px;
}

.form__input{
    width:100%;
    padding:0 10px ;
    height:40px;
    border-radius:9px;
    border:none;
    outline:none;
    border:1px solid #ccc;
}

.login__submit__btn{
    width:100%;
    text-align: center;
    background: var(--blue);
    color:white;
    padding:10px 0;
    border:none;
    outline:none;
    font-size:18px;
    margin-top: 20px;
    cursor: pointer;
}
.admin__container{
    display:grid;
    place-items:center;
    padding:0 4em;
    background: #efefef;
}

.admin__heading{
    margin-bottom: 40px;
    font-size:40px;
}

.admin__table,tr,td,th{
    border:1px solid black;
    background: white;
}

.admin__table{
    border-collapse: collapse;
    margin-bottom: 40px;
}

td,th{
    padding:20px;
    font-size:18px;
}

.logout__container{
    text-align: right;
    display: block;
    width:100%;
}

.logout__btn{
    text-align: center;
    background: var(--blue);
    color:white;
    padding:10px 30px;
    border:none;
    outline:none;
    font-size:18px;
    margin-top: 20px;
    cursor: pointer;
}

.blog__form__container{
    padding:0 4em;
    display: grid;
    place-items: center;
    background: #efefef;
}

.blog__form__container form{
    width:100%;
    max-width:500px;
    margin-bottom: 40px;
}

.form__input__file{
    display: block;
    width:100%;
    margin-top: 5px;
}

.form__textarea{
    height:200px;
    display: block;
    width:100%;
    border:1px solid #ccc;
    border-radius: 9px;
    padding:10px;
    outline: none;
    color:black;
    font-size:14px;
    resize:none;
}

.percentage__bar__container{
    height:4px;
    width:100%;
    background:white;
    border-radius:5px;
}

.percentage__bar{
    width:0px;
    height:100%;
    background:var(--blue);
    box-shadow:0px 2px 4px 0px rgba(0,0,0,0.1);
}

.success{
    color:rgb(11, 185, 11);
}
.blog__container{
    font-family: 'Rubik';
}

.blog__header{
    max-width:100vw;
    max-height:500px;
    position:relative;
}

.blog__header__image{
    width:100%;
    height:100%;
} 

.blog__header__image img{
    width:100%;
    height:100%;
    object-fit:contain;
}

.blog__header__text{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.blog__header__text img{
    max-width:800px;
}

.blog__content__container{
    padding:70px 60px 70px 100px; 
    display: flex;
}

.blog__content__container .left__container{
    flex:0.7 1;
    margin-right: 50px;
}

.blog__image__container{
    max-width:100%;
    height:500px;
    border-radius:16px;
    overflow: hidden;
    background-image: linear-gradient(317deg, #e08aff 97%, #5a51e4 -63%);
}

.blog__image{
    width:100%;
    height:100%;
    object-fit: cover;
}

.blog__date__container{
    margin-top:70px;
    margin-bottom:38px;
    color:var(--lightBlack);
    font-size:30px;
    display: flex;
    align-items: center;
}

.blog__date__container img{
    max-width:41px;
    margin-left: 50px;
    cursor: pointer;
}

.blog__title{
    font-size:60px;
    font-weight:var(--bold);
    margin-bottom: 38px;
}

.blog__content{
    font-size:35px;
    line-height: 57px;
    font-weight:var(--reglar);
}

.blog__footer{
    margin: 70px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog__writter__name{
    font-size:39px;
    color:var(--lightBlack);
}

.share__blog__btn{
    font-size:33px;
    border:none;
    outline:none;
    background-color: var(--white);
    color:var(--blue);
    padding:14px 44px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.16);
    cursor: pointer;
}

.share__blog__btn img{
    margin-left: 30px;
}

.blog__writter__name .name{
    color:var(--black);
}

.blog__content__container .right__container{
    flex:0.3 1;
}

.recent__blogs__heading{
    font-size:35px;
    font-weight:var(--bold);
    margin:25px 0;
}

.recent__blogs__container{
    display: flex;
    flex-direction: column;
}

.recent__blog__card{
    width:100%;
    padding:15px 30px;
    border-radius: 15px;
    box-shadow: 0px 3px 28px 0px #f2f2f2;
    margin-bottom: 20px;
    word-wrap: break-word;
}

.recent__blog__date{
    font-size:20px;
    color:var(--lightBlack);
    margin-bottom: 10px;
}

.recent__blog__title a{
    font-size:24px;
    line-height:38px;
    font-weight: var(--medium);
    color:var(--black);
}

.blog__actions__container{
    display: flex;
    align-items: stretch;
}

.blog__actions__container .like__btn,
.blog__actions__container .comment__btn{
    border:none;
    background: white;
    outline:none;
    font-size:30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color:#c9c6c6;
}

.blog__actions__container .active{
    animation:beat 1 0.5s ease-in;
}

.more__btn{
    border-radius: 5px;
    background:var(--blue);
    color:white;
    font-weight: var(--medium);
    padding:12px;
    font-size: 18px;
    border:none;
    outline:none;
    box-shadow: 0px 3px 28px 0px #f2f2f2;
}

@keyframes beat{
    from{
        opacity: 0.4;
        transform: scale(0.9);
    }
    to{
        opacity: 1;
        transform: scale(1.2);
    }
}

.blog__actions__container .like__btn span,
.blog__actions__container .comment__btn span{
    border:none;
    background: white;
    outline:none;
    font-size:12px;
    margin-top: 5px;
}

.blog__actions__container .like__btn{
    margin-right: 30px;
}

.blog__comment__wrapper{
    background: rgba(0,0,0,0.3);
    height:100vh;
    width:100vw;
    position:fixed;
    top:0;
    left:0;
    display: grid;
    place-items: center;
}

.blog__comment__container{
    width:500px;
    background: white;
    border-radius: 10px;
    padding:30px;
}

.blog__comment__container .blog__title{
    text-align: center;
    color:var(--black);
}

.blog__comment__container .form__textarea{
    height:120px;
}

.comment__btns{
    text-align: center;
}

.comment__submit__btn,.comment__cancel__btn{
    padding:8px 20px;
    color:white;
    background: var(--blue);
    border:none;
    outline:none;
    cursor: pointer;
}

.show__more__btn__container{
    text-align: center;
}

.show__more__btn__container button{
    width:100%;
    cursor: pointer;
}

.comment__cancel__btn{
    background: tomato;
    margin-left: 20px;
}

.loading{
    position:fixed;
    top:0;
    left:0;
    height:100vh;
    width:100%;
    background: rgba(0,0,0,0.7);
    color:white;
    z-index:9999999;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading .bubbles{
    animation:fade1 1s infinite linear;
    background: crimson;
    height:30px;
    width:30px;
    border-radius: 50%;
    margin-left: 30px;
}

.loading .bubble2{
    animation:fade2 1s infinite linear;
    background:cyan;
}

.loading .bubble3{
    animation:fade3 1s infinite linear;
    background:darkorange;
}

@keyframes fade1 {
    0%{
        transform: scale(0.5);
    }
    50%{
        transform: scale(0.8);
    }
    100%{
        transform: scale(1.1);
    }
}

@keyframes fade2 {
    0%{
        transform: scale(0.8);
    }
    50%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(0.5);
    }
}

@keyframes fade3 {
    0%{
        transform: scale(1.1);
    }
    50%{
        transform: scale(0.5);
    }
    100%{
        transform: scale(0.8);
    }
}

.comments__container{
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}

.comment__card{
    border-bottom: 1px solid #efefef;
    padding:10px;
}

.comment__body span{
    color:#949292;
    font-size:14px;
}

.comment__body span.name{   
    color:#5a51e4;
}

.comment__body span.date{   
    color:#949292;
    font-size: 12px;
}

.coming__soon__container{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    height:100vh;
    width:100%;
    background: var(--blue);
    z-index:99999999;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.coming__soon__container h1{
    font-size: 64px;
    font-family: 'Rubik';
    color:white;
}

.coming__soon__container a{
    font-size: 32px;
    padding:10px 30px;
    background: var(--white);
    color:var(--blue);
    border-radius: 9px;
    border:2px solid transparent;
    outline:none;
    transition:all 0.2s linear;
    cursor: pointer;
    margin-top: 50px;
}

.coming__soon__container a:hover{
    background: var(--blue);
    color:var(--white);
    border:2px solid white;
    box-shadow: 0px 0px 28px 0px rgba(255,255,255,0.30);
}

@media screen and (max-width:1450px){
    .blog__image__container{
        max-height:400px;
    }
    .blog__date__container{
        margin-top: 60px;
        margin-bottom: 30px;
        font-size:24px;
    }
    .blog__date__container img{
        max-width:30px;
    }
    .blog__title{
        font-size:40px;
        margin-bottom: 30px;
    }
    .blog__content{
        font-size:20px;
        line-height:34px;
    }
    .blog__footer{
        margin:40px 0;
    }
    .blog__writter__name{
        font-size: 24px;
    }
    .share__blog__btn{
        font-size:20px;
        padding:12px 30px;
        border-radius:10px;
    }
    .share__blog__btn img{
        max-width:25px;
        margin-left: 20px;
    }
    .recent__blogs__heading{
        font-size:24px;
        margin:20px 0;
    }
    .recent__blog__date{
        font-size: 13px;
    }
    .recent__blog__title a{
        font-size: 18px;
        line-height: 23px;
    }
}

@media screen and (max-width:992px){
    .blog__content__container{
        padding:40px 30px 40px 50px;
    }
    .blog__image__container{
        max-height:350px;
    }
    .blog__content__container .left__container{
        margin-right: 30px;
    }
    .blog__date__container{
        margin-top: 40px;
    }
}

@media screen and (max-width:800px){
    .blog__header__text img{
        max-width:500px;
    }
}

@media screen and (max-width:768px){
    .blog__content__container{
        flex-direction: column
    }
    .blog__content__container .left__container{
        margin-right: 0;
    }
    .recent__blogs__container{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .recent__blog__card{
        width:48%;
    }
}

@media screen and (max-width:510px){
    .blog__comment__container{
        width:95%;
        margin:0 auto;
        padding:30px 15px;
    }
    .blog__comment__container label{
        font-size: 12px;
    }
    .blog__comment__container input{
        height:35px;
        font-size: 12px;
        border-radius: 4px;
    }
    .blog__comment__container textarea{
        font-size: 12px;
        border-radius: 4px;
    }
    .coming__soon__container h1{
        font-size: 32px;
    }
    .coming__soon__container a{
        font-size: 16px;
        padding:7px 20px;
        margin-top: 30px;
        border-radius: 4px;
    }

}

@media screen and (max-width:500px){
    .blog__date__container{
        margin:20px 0;
        margin-top:30px;
    }
    .blog__header__text img{
        max-width:300px;
    }
    .blog__content__container{
        padding:20px;
    }
    .blog__date__container{
        font-size:16px;
    }
    .blog__date__container img{
        margin-left: 30px;
        max-width:20px;
    }
    .blog__title{
        margin-bottom: 15px;
        font-size:24px;
    }
    .blog__writter__name{
        font-size: 16px;
    }
    .share__blog__btn{
        font-size: 14px;
        padding:8px 15px;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    .share__blog__btn img{
        max-width:16px;
    }
    .blog__image__container{
        max-height: 300px;
    }
    .recent__blogs__heading{
        font-size: 18px;
    }
    .recent__blog__card{
        padding:10px 20px;
        border-radius: 7px;
    }
    .recent__blog__date{
        font-size: 11px;
    }
    .recent__blog__title a{
        font-size: 16px;
    }
    .blog__content{
        font-size: 14px;
        line-height:26px;
    }
    .blog__footer{
        align-items: flex-start;
        flex-direction: column-reverse;
    }
    .blog__image__container{
        border-radius: 8px;
    }
    .more__btn{
        font-size: 14px;
        padding: 8 20px;
    }
}

@media screen and (max-width:400px){
    .recent__blog__card{
        margin-bottom: 20px;
        width:100%
    }
    .blog__image__container{
        max-height:200px;
    }
}

@media screen and (max-width:330px){
    .blog__content__container{
        padding:20px 10px;
    }
}
.why__choose__us__container{
    background:var(--bgGray);
    font-family:'Josefin Sans','Passion One';
    min-height: 100vh;
    padding:100px 100px 100px 100px;
}

.why__choose__us__heading .main__heading{
    font-family: 'Passion One';
    font-weight:var(--regular);
    margin-bottom:75px;
}

.why__choose__us__cards{
    display:flex;
    align-items: stretch;
    justify-content: center;
}

.why__choose__us__card{
    border-radius:15px;
    background:var(--white);
    overflow: hidden;
    box-shadow: 0px 3px 59px 0px rgba(0,0,0,0.18);
    max-width:380px;
    transition:all 0.1s linear;
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.why__choose__us__card__heading{
    font-family: 'Passion One';
    text-align: center;
    font-weight:var(--regular);
    padding:25px 0;
    background:var(--newBlueLinearGradiant);
    color:var(--white);
    font-size:30px;
}

.why__choose__us__card__content{
    font-family: 'Josefin Sans';
    font-weight:var(--regular);
    color:var(--lightBlack);
    padding:29px 34px 17px 22px;
    font-size:19px;
    line-height:26px;
    -webkit-font-smoothing: subpixel-antialiased;
    transition: all 0.2s linear;

}

.why__choose__us__card:nth-child(2){
    margin:-25px;
    z-index:1;
    margin-bottom:-30px;
}

.why__choose__us__card:nth-child(2) .why__choose__us__card__content{
    padding:25px 19px;
} 

.why__choose__us__card:nth-child(3) .why__choose__us__card__content{
    padding:29px 16px 17px 34px;
} 

.why__choose__us__card:nth-child(1):hover {
    transform: translate(-20px,0);
}

.why__choose__us__card:nth-child(1):hover > .why__choose__us__card__content {
    padding-right: 20px;
}

.why__choose__us__card:nth-child(3):hover {
    transform: translate(20px,0);
}

.why__choose__us__card:nth-child(3):hover > .why__choose__us__card__content {
    padding-left: 20px;
}

.why__choose__us__card:nth-child(2):hover{
    transform: translateY(-20px);
}

.why__choose__us__card.mobile__choose__card{
    max-width:500px;
    height:100%;
    margin:0 auto;
    padding-bottom: 20px;
}

.mobile__choose__card .why__choose__us__card__content{
    font-size: 21px;
    text-align: left;
}

.why__choose__us__cards .carousel.carousel-slider{
    overflow: unset;
}

.why__choose__us__cards .carousel-root{
    max-width: 100%;
}

.why__choose__us__cards  .carousel .control-dots{
    bottom:-40px
}

.why__choose__us__cards .carousel .slider-wrapper.axis-horizontal .slider .slide{
    padding-bottom:20px;
    background: transparent;
}

@media only screen and (max-width:1250px){
    .why__choose__us__card {
        max-width:350px;
    }
    .why__choose__us__container{
        padding:100px 50px;
    }
}

@media only screen and (max-width:1100px){
    .why__choose__us__card {
        max-width:320px;
    }
    .why__choose__us__container{
        padding:100px 25px;
    }
    .why__choose__us__card:nth-child(2) .why__choose__us__card__content{
        padding:20px;
    }
    .why__choose__us__card:nth-child(3) .why__choose__us__card__content{
        padding:20px;
        padding-left: 29px;
    }
    .why__choose__us__card__content{
        padding:20px;
        padding-right: 25px;
        font-size: 16px;
    }
}

@media only screen and (max-width:940px){
    .why__choose__us__container{
        padding:50px 25px;
        padding-bottom: 75px;
    }
    .why__choose__us__card{
        max-width:290px;
    }
}

@media only screen and (max-width:768px){
    .why__choose__us__card.mobile__choose__card{
        max-width:400px;
        height:100%;
    }
    .why__choose__us__card:nth-child(1):hover {
        transform: none;
    }
    
    .why__choose__us__card:nth-child(1):hover > .why__choose__us__card__content {
        padding-right: none;
    }
    
    .why__choose__us__card:nth-child(3):hover {
        transform: none;
    }
    
    .why__choose__us__card:nth-child(3):hover > .why__choose__us__card__content {
        padding-left: none;
    }
    
    .why__choose__us__card:nth-child(2):hover{
        transform: none;
    }
    .why__choose__us__heading .main__heading{
        margin-bottom: 21px;
    }
}

@media only screen and (max-width:500px){
    .why__choose__us__container{
        padding:50px 0px;
        min-height: unset;
    }
    .why__choose__us__card.mobile__choose__card{
        max-width: 100%;
    }
    .why__choose__us__cards .carousel .slider-wrapper.axis-horizontal .slider .slide{
        padding:30px;
        background: var(--bgGray);
    }
    .why__choose__us__card__heading{
        font-size: 20px;
        letter-spacing: 0.6px;
        padding:20px 0;
    }
    .mobile__choose__card .why__choose__us__card__content{
        font-size: 16px;
        padding-bottom: 40px;
    }
    .why__choose__us__card.mobile__choose__card{
        padding-bottom: 0;
    }
}


@media only screen and (max-width:350px){
    .why__choose__us__cards .carousel .slider-wrapper.axis-horizontal .slider .slide{
        padding: 15px;
    }

}
.move__to__top__container{
    background-color: var(--bgGray);
    height:40px;
    width:40px;
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.5);
    border-radius: 50%;
    position: fixed;
    bottom:10px;
    right:10px;
    display: grid;
    transition: all 0.2s linear;
    place-items: center;
    cursor: pointer;
    z-index:8;
}

.move__to__top__btn{
    font-size:20px;
}

.move__to__top__container:hover{
    transform: scale(1.15);
}
.casestudy__container {
  min-height: 100vh;
  display: flex;
  padding: 4em;
  font-family: "Josefin Sans";
  background: var(--bgGray);
  align-items: center;
  justify-content: space-evenly;
}

.casestudy__heading .main__heading {
  text-align: left;
  margin-bottom: 20px;
}

.casestudy__heading .secondary__heading p {
  margin-bottom: 16px;
}

.casestudy__button a {
  padding: 12px;
  display: inline-block;
  width: unset;
  border: 1px solid #3991a5;
  background-color: #3991a5;
  color: var(--white);
  border-radius: 5px;
}

.casestudy__button a:hover {
  background-color: var(--white);
  color: #3991a5;
  transition: all 0.2s linear;
}

.casestudy__button a span {
  margin-right: 10px;
}

.casestudy__image__container {
  max-width: 580px;
  flex: 0.6 1;
  margin-right: 80px;
}

.casestudy__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.casestudy__content__container {
  flex: 0.4 1;
}

@media screen and (max-width: 1100px) {
  .casestudy__container {
    padding: 50px 2em;
  }
}

@media screen and (max-width: 900px) {
  .casestudy__container {
    padding: 50px 1em;
  }
  .casestudy__image__container {
    margin-right: 40px;
  }
  .casestudy__content__container {
    flex: 0.5 1;
  }
}

@media screen and (max-width: 768px) {
  .casestudy__container {
    flex-direction: column-reverse;
    padding: 50px 2em;
    min-height: unset;
  }
  .casestudy__image__container {
    margin-top: 50px;
    margin-right: 0;
    max-width: 500px;
  }
}

@media screen and (max-width: 500px) {
  .casestudy__container {
    padding: 50px 1em;
  }
  .casestudy__button a {
    padding: 10px;
    font-size: 14px;
  }
  .casestudy__heading .main__heading {
    margin-bottom: 10px;
  }
  .casestudy__image__container {
    margin-top: 30px;
    max-width: 100%;
  }
}


.casestudy__card__container{
    width:45%;
    max-width: 550px;
    border-radius: 18px;
    margin-bottom:30px;
}

.odd__casestudy__card{
    margin-top:50px;
}

.casestudy__card__image__container{
    width:100%;
    position: relative;
    transition:all 0.3s linear;
}

.casestudy__card__image__overlay{
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,0.14);
    background-image: linear-gradient(to bottom,
    rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.14),rgba(0, 0, 0, 0.51)    );
    border-radius: 18px;
}

.casestudy__card__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 18px;
}

.casestudy__card__logo__container{
    height:70px;
    width:70px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    background: var(--white);
    position: absolute;
    top: 20px;
    left: 20px;
    z-index:1;
    transition:all 0.3s linear;
}

.casestudy__card__logo{
    max-width:30px;
    object-fit: contain;
}

.casestudy__card__image__container:hover{
    transform: scale(0.96);
}

.casestudy__card__image__container:hover .casestudy__card__logo__container{
    top:-25px;
    left:-25px;
    transform:scale(1.1);
}

.casestudy__card__mobile__container{
    max-width:150px;
    position: absolute;
    right:0;
    bottom:0;
    transition:all 0.3s linear;
    transform: scale(0);
}

.casestudy__card__mobile{
    width:100%;
    height:100%;
    object-fit: contain;
}

.casestudy__card__image__container:hover .casestudy__card__mobile__container{
    right:-70px;
    bottom:-45px;
    transform:scale(1);
}

.casestudy__card__content__container{
    text-align: center;
}

.casestudy__card__title{
    font-size:35px;
    color:var(--black);
    font-family:'Passion One';
    margin:30px 0px 10px 0;
}

.casestudy__card__tagline{
    font-size: 18px;
    font-family:'Josefin Sans';
    line-height: 24px;
    margin-bottom: 15px;
    white-space: pre-wrap;
}

.casestudy__card__technology__icons img{
    max-height:18px;
    object-fit: contain;
}

.casestudy__card__technology__icons img:not(:last-child){
    margin-right: 14px;
}

@media screen and (max-width:1100px){
    .casestudy__card__logo__container{
        height:60px;
        width:60px;
    }
}

@media screen and (max-width:768px) {
    .odd__casestudy__card{
        margin-top: 30px;
    }
    .casestudy__card__container{
        width:47%;
    }
    .casestudy__card__logo__container{
        height: 40px;
        width: 40px;
    }
    .casestudy__card__logo{
        max-width:25px !important;
        max-height:25px !important;
    }
    .casestudy__card__logo__container{
        top: 10px;
        left: 10px;
    }
    .casestudy__card__image__container:hover{
        transform: scale(1);
    }
    .casestudy__card__image__container:hover .casestudy__card__logo__container{
        top: 10px;
        left: 10px;
        transform:scale(1);
    }
    .casestudy__card__image__container:hover .casestudy__card__mobile__container{
        display: none;
    }
    .casestudy__card__title{
        font-size: 30px;
        margin:15px 0px 5px 0;
    }
    .casestudy__card__tagline{
        font-size: 14px;
        margin-bottom: 6px;
        line-height: 19px;
    }
    .casestudy__card__technology__icons img{
        max-height:14px;
    }
    .casestudy__card__container:first-child .casestudy__card__logo{
        max-width:56px !important;
    }
}

@media screen and (max-width:600px) {
    .casestudy__cards__container{
        flex-direction: column;
    }
    .odd__casestudy__card{
        margin-top: 0px;
    }
    .casestudy__card__container{
        width:100%;
    }
    .casestudy__card__logo__container{
        height: 60px;
        width: 60px;
    }
    .casestudy__card__logo{
        max-width:45px;
        max-height:45px;
    }
    .casestudy__card__logo__container{
        top: 15px;
        left: 15px;
    }
}

@media screen and (max-width:450px) {
    .casestudy__card__logo__container{
        height: 40px;
        width: 40px;
    }
    .casestudy__card__logo{
        max-width:25px !important;
        max-height:25px !important;
    }
    .casestudy__card__container:nth-child(2) .casestudy__card__logo{
        max-width:17px !important;
    }
    .casestudy__card__title{
        font-size: 25px;
    }
    .casestudy__card__tagline{
        font-size: 12px;
    }
}
.casestudy__banner__container{
    padding:4em 4em 6em 4em;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row-reverse;
    align-items: center;
}

.casestudy__main__container .casestudy__main__image__container{
    max-width: 500px;
}

.casestudy__main__container .casestudy__content__container{
    flex:0.45 1;
    margin-right: 100px;
}

.casestudy__main__image__container{
    flex:0.5 1;
}

.casestudy__main__container .casestudy__content__container a span{
    margin-right: 0;
}

.casestudy__cards__container{
    background: var(--bgGray);
    padding: 8em 6em;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media screen and (max-width:1100px) {
    .casestudy__banner__container{
        padding:4em 2em;
    }
    .casestudy__main__image__container{
        flex:0.45 1;
    }
    .casestudy__cards__container{
        padding:6em 4em;
    }
}

@media screen and (max-width:900px) {
    .casestudy__banner__container{
        padding:4em 2em;
        justify-content: space-between;
    }
    .casestudy__main__container .casestudy__content__container{
        margin-right: 50px;
    }
    .casestudy__main__image__container{
        flex:0.55 1;
    }
    .casestudy__cards__container{
        padding:4em 2em;
    }
}

@media screen and (max-width:768px) {
    .casestudy__banner__container{
        padding:2em;
        flex-direction: column;
    }
    .casestudy__main__container .casestudy__content__container{
        margin-right: 0px;
    }
    .casestudy__main__image__container{
        margin-bottom: 50px;
    }
    .casestudy__main__container .casestudy__main__image__container{
        max-width: 400px;
    }
}

@media screen and (max-width:768px) {
    .casestudy__banner__container{
        padding:1em;
        padding-bottom: 3em;
    }
}

@media screen and (max-width:450px) {
    .casestudy__cards__container{
        padding: 3em 1em;
    }
    .casestudy__main__container .casestudy__main__image__container{
        max-width:350px;
    }
}

@media screen and (max-width:375px) {
    .casestudy__cards__container{
        padding: 3em 1em;
    }
    .casestudy__main__container .casestudy__main__image__container{
        max-width:300px;
    }
}

@media screen and (min-width:1500px){
    .casestudy__main__container .casestudy__content__container{
        max-width:600px;
    }
}



.clients_container {
    background: #282828;
    padding: 4rem 2rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1800px;
    margin: auto;
    grid-gap: 3rem;
    gap: 3rem;
    overflow: hidden;
    flex-wrap: wrap;

    >div:nth-child(1) {
        max-width: 650px;
    }

    >div:nth-child(2) {
        max-width: 550px;
        grid-gap: 2rem;
        gap: 2rem;
        flex-wrap: wrap;
        align-items: center;

        >div{
            display: flex;
            flex-direction: column;
            grid-gap: 1rem;
            gap: 1rem;
        }
    }
}

.clients_container h2 {
    font-weight: 400;
    font-size: 2rem;
    margin: .2rem 0;
}

.groomer_text {
    font-size: medium;
    font-style: italic;
    font-weight: 300;
    opacity: .6;
    text-transform: uppercase;
    padding: .2rem;
}


.groomer_text_2 {
    font-size: medium;
    font-weight: 300;
    opacity: .6;
    padding: .2rem;
    margin-top: .5rem;
}

.number_grid_container {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 3rem;
}

.number_grid {
    flex: 1 1;
    border-left: 1px solid #ffffff50;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: .5rem;

    >p {
        opacity: .6;
        font-size: small;
    }
}



/* @media only screen and (max-width: 900px) {
    .clients_container {
        >div:nth-child(2) {
            flex-direction: column;
            gap: 2rem;

            >div:nth-child(2) {
                align-items: center;
            }
        }
    }
}*/

@media only screen and (max-width: 600px) {
    .clients_container {
        >div:nth-child(2){
            >div{
                flex-direction: row;
            }
        }
    }

    .number_grid_container {
        flex-direction: column;
        align-items: self-start;
    }
} 


/* .cir_prog_container {
    border: 3px solid red;
    border-radius: 100%;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    >h2 {
        font-size: 2rem;
        text-align: center;
    }

    >p{
        opacity: .6;
        text-transform: uppercase;
        font-size: smaller;
    }
} */



.circle-background,
.circle-progress {
    fill: none;
}

.circle-background {
    stroke: #ffffff40;
}

.circle-progress {
    stroke: red;
    stroke-linecap: round;
    stroke-linejoin: round;
}

/* .circle-text {
    font-size: 3em;
    fill: red;
} */

.progress_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    animation: bottomToPostionAnim linear;
    animation-timeline: scroll();

    >svg {
        position: absolute;
        left: 0;
    }
}

@animation scroll-watcher {
    to {
        scale: 1 1;
    }
}

.circle_text_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    >h2 {
        font-size: 2rem;
        text-align: center;
    }

    >p {
        opacity: .6;
    }
}



@keyframes appear {
    0% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}
.hidden {
    transition: all 1s;
    visibility: none;
}

.show {
    visibility: visible;
}

@keyframes bottomToPostionAnim {
    0% {
        opacity: 0;
        transform: translateY(-20%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }

}


@keyframes rightToPostionAnim {
    0% {
        opacity: 0;
        transform: translateX(20%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }

}


.animate-me {
    max-width: 650px;
    opacity: 0;
    transition: opacity 1s, transform 1s;
}

.animateTop {
    animation: bottomToPostionAnim 1s forwards;
}
.animateLeft {
    animation: rightToPostionAnim 1s forwards;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family:'Rubik','Roboto','Passion One','Josefin Sans',sans-serif;
}

html{
    scroll-behavior: smooth;
}

.hide{
    display: none;
}

:root{
    --black : #333333;
    --lightBlack : #555555;
    --blue: #43adc4;
    --darkBlue:#005e72;
    --darkGreen:#026d4f;
    --white:#ffffff;
    --bgGray:#f5f5f5;
    --regular:400;
    --medium:500;
    --bold:700;
    --headerHeight:100px;
    --textLinearGradiant:linear-gradient(to bottom right , #7D60EB , #E08AFF);
    --greenLinearGradiant:linear-gradient(to bottom right, #2BF4A8 , #23BC82);
    --purpleLinearGradiant:linear-gradient(to bottom right, #F5A2BC , #B370D9);
    --blueLinearGradiant:linear-gradient(to bottom right, #7AC5E4 , #0A6DFC);
    --orangeLinearGradiant:linear-gradient(to bottom right, #F5C25E , #D8A33A);
    --redLinearGradiant:linear-gradient(to bottom right, #F76464 , #BC1A39);
    --pinkOrangeLinearGradiant:linear-gradient(to bottom right, #F934A7 , #F6C155);
    --lightGreenLinearGradiant:linear-gradient(to bottom right, #C4D33B , #31A315);
    --pinkLinearGradiant:linear-gradient(to bottom right, #F24B94 , #D30636);
    --blueGreenLinearGradiant:linear-gradient(to bottom right, #76CCD1 , #498F33);
    
    --b1LinearGradiant:linear-gradient(119deg, #6fe2fb 0%, #025e72 100%);
    --b2LinearGradiant:linear-gradient(111deg, #64dfd9, #028e63 98%);

    --b1IconsLinearGradiant:linear-gradient(to top, #00738de3, #004c5be3);
    --b2IconsLinearGradiant:linear-gradient(to top, #03946b , #014130);
    
    --newBlueLinearGradiant:linear-gradient(105deg, #6fe2fb 0%, #025e72 100%);

    --p1Color : #2b478b;
    --p2Color : #0f8849;
    --p3Color : #d49d00;
    --p4Color : #b26401;

    --p1BgColor : #dfebf9;
    --p2BgColor : linear-gradient(103deg, #cbfdbb, #61f3d0 92%);
    --p3BgColor : #f5f5f5;
    --p4BgColor : #feefdc;

}

a{
    text-decoration: none;
}

ul{
    list-style: none;
}

nav,button{
    font-family: 'Josefin Sans';
    font-weight: var(--regular);
}

.main__heading{
    font-size:44px;
    font-family:'Passion One';
    text-align:center;
    color:var(--black);
}

.main__secondary__heading{
    font-size:24px;
    margin-bottom: 16px;
    font-family: 'Josefin Sans';
    color:var(--black);
    font-weight: var(--bold);
}

.secondary__heading{
    font-size: 20px;
    line-height: 26px;;
    color:var(--lightBlack);
}

.carousel .slide{
    background:transparent;
}

.carousel .control-dots .dot{
    background: var(--blue);
    margin-top: 20px;
    outline: none;
}

@media only screen and (max-width:500px){
    .main__heading{
        font-size:25px;
        color:var(--black);
    }
    .main__secondary__heading{
        font-size: 18px;
        margin-bottom: 10px;
    }
    .secondary__heading{
        font-size: 16px;
        line-height: 24px;
    }
}

body::-webkit-scrollbar{
    background:var(--bgGray);
    width:10px;
    border-radius: 8px;
}

body::-webkit-scrollbar-thumb{
    background: #919090;
    border-radius: 8px;
}
