.move__to__top__container{
    background-color: var(--bgGray);
    height:40px;
    width:40px;
    box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.5);
    border-radius: 50%;
    position: fixed;
    bottom:10px;
    right:10px;
    display: grid;
    transition: all 0.2s linear;
    place-items: center;
    cursor: pointer;
    z-index:8;
}

.move__to__top__btn{
    font-size:20px;
}

.move__to__top__container:hover{
    transform: scale(1.15);
}