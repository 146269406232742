.admin__container{
    display:grid;
    place-items:center;
    padding:0 4em;
    background: #efefef;
}

.admin__heading{
    margin-bottom: 40px;
    font-size:40px;
}

.admin__table,tr,td,th{
    border:1px solid black;
    background: white;
}

.admin__table{
    border-collapse: collapse;
    margin-bottom: 40px;
}

td,th{
    padding:20px;
    font-size:18px;
}

.logout__container{
    text-align: right;
    display: block;
    width:100%;
}

.logout__btn{
    text-align: center;
    background: var(--blue);
    color:white;
    padding:10px 30px;
    border:none;
    outline:none;
    font-size:18px;
    margin-top: 20px;
    cursor: pointer;
}
