.hidden {
    transition: all 1s;
    visibility: none;
}

.show {
    visibility: visible;
}

@keyframes bottomToPostionAnim {
    0% {
        opacity: 0;
        transform: translateY(-20%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }

}


@keyframes rightToPostionAnim {
    0% {
        opacity: 0;
        transform: translateX(20%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }

}


.animate-me {
    max-width: 650px;
    opacity: 0;
    transition: opacity 1s, transform 1s;
}

.animateTop {
    animation: bottomToPostionAnim 1s forwards;
}
.animateLeft {
    animation: rightToPostionAnim 1s forwards;
}