.clients_container {
    background: #282828;
    padding: 4rem 2rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1800px;
    margin: auto;
    gap: 3rem;
    overflow: hidden;
    flex-wrap: wrap;

    >div:nth-child(1) {
        max-width: 650px;
    }

    >div:nth-child(2) {
        max-width: 550px;
        gap: 2rem;
        flex-wrap: wrap;
        align-items: center;

        >div{
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
}

.clients_container h2 {
    font-weight: 400;
    font-size: 2rem;
    margin: .2rem 0;
}

.groomer_text {
    font-size: medium;
    font-style: italic;
    font-weight: 300;
    opacity: .6;
    text-transform: uppercase;
    padding: .2rem;
}


.groomer_text_2 {
    font-size: medium;
    font-weight: 300;
    opacity: .6;
    padding: .2rem;
    margin-top: .5rem;
}

.number_grid_container {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 3rem;
}

.number_grid {
    flex: 1;
    border-left: 1px solid #ffffff50;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: .5rem;

    >p {
        opacity: .6;
        font-size: small;
    }
}



/* @media only screen and (max-width: 900px) {
    .clients_container {
        >div:nth-child(2) {
            flex-direction: column;
            gap: 2rem;

            >div:nth-child(2) {
                align-items: center;
            }
        }
    }
}*/

@media only screen and (max-width: 600px) {
    .clients_container {
        >div:nth-child(2){
            >div{
                flex-direction: row;
            }
        }
    }

    .number_grid_container {
        flex-direction: column;
        align-items: self-start;
    }
} 


/* .cir_prog_container {
    border: 3px solid red;
    border-radius: 100%;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    >h2 {
        font-size: 2rem;
        text-align: center;
    }

    >p{
        opacity: .6;
        text-transform: uppercase;
        font-size: smaller;
    }
} */



.circle-background,
.circle-progress {
    fill: none;
}

.circle-background {
    stroke: #ffffff40;
}

.circle-progress {
    stroke: red;
    stroke-linecap: round;
    stroke-linejoin: round;
}

/* .circle-text {
    font-size: 3em;
    fill: red;
} */

.progress_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    animation: bottomToPostionAnim linear;
    animation-timeline: scroll();

    >svg {
        position: absolute;
        left: 0;
    }
}

@animation scroll-watcher {
    to {
        scale: 1 1;
    }
}

.circle_text_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    >h2 {
        font-size: 2rem;
        text-align: center;
    }

    >p {
        opacity: .6;
    }
}



@keyframes appear {
    0% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}