/* arrow buttons */

.arrow-btn-left {
  position: absolute;
  background-color: #ffffff5d; 
  border: none;
  cursor: pointer;
  top: 50%;
  left: 0;
  width: 32px;
  height: 44px;
  overflow: hidden;
  z-index: 9;
  border-radius: 0 8px 8px 0;
}

.arrow-btn-right {
  position: absolute;
  background-color: #ffffff5d; 
  border: none;
  cursor: pointer;
  top: 50%;
  right: 0;
  width: 32px;
  height: 44px;
  overflow: hidden;
  z-index: 9;
  border-radius: 8px 0 0 8px;

}


/**/



.banner-container {
  min-height: 100vh;
  padding-top: 100px;
  display: flex;
}

.banner-container .bannerPage2,
.banner-container .bannerPage4 {
  flex-direction: row-reverse;
}

.banner-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.banner-container .banner-content a {
  text-decoration: none;
  color: #fff;
  text-align: left;
  width: fit-content;
  height: fit-content;
}

.bannerPage1,
.bannerPage3 {
  padding-left: 84px;
}

.banner-content .left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bannerPage1 .left-container {
  max-width: 437px;
}

.bannerPage2 .left-container {
  margin: 0 auto 0 40px;
  max-width: 421px;
}

.bannerPage3 .left-container {
  max-width: 700px;
}

.bannerPage4 .left-container {
  margin: 0 auto 0 40px;
  max-width: 540px;
}

.bannerPage2 .left-container ul {
  text-align: left;
  margin-bottom: 21px;
}

.bannerPage2 .left-container li::before {
  content: "• ";
  color: green;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.banner-content .main-heading {
  font-size: 47px;
  text-align: left;
  line-height: 53px;
  letter-spacing: 1.5px;
  margin: 0 0 11.6px;
  font-family: Rubik;
  font-weight: bold;
  color: #333;
}

.banner-content .left-container p {
  font-size: 22px;
  text-align: left;
  line-height: 30px;
  margin: 11.6px 0 26.3px;
  letter-spacing: normal;
  color: #333;
}

.banner-content .left-container button {
  border: 1px solid #3991a5;
  width: fit-content;
  padding: 13px 21px 12.6px 22px;
  border-radius: 5px;
  background-color: #3991a5;
  font-size: 18px;
  text-align: center;
  color: var(--white);
}

.banner-content .left-container button:hover {
  background-color: var(--white);
  color: #3991a5;
  transition: all 0.2s linear;
}

.banner-content .right-container {
  margin: auto 0;
}

.bannerPage1 .right-container,
.bannerPage3 .right-container {
  margin-left: 20px;
  margin-right: 20px;
}

.bannerPage2 .right-container,
.bannerPage4 .right-container {
  margin-left: 30px;
}

.bannerPage4 .right-container {
  position: relative;
}

.bannerPage4 .right-container .rightContainer-InnerImg {
  position: absolute;
  left: 0;
}

.banner-content .right-container img {
  max-width: 700px;
  max-height: 647px;
  object-fit: contain;
}

.bannerPage3 .right-container img {
  max-height: 600px;
}

.bannerPage4 .right-container img {
  height: 627px;
}

.bannerPage3 .right-container .right-container-content,
.bannerPage2 .right-container .right-container-content {
  position: relative;
}

.bannerPage3 .right-container .designation-container,
.bannerPage2 .right-container .designation-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 42px;
  background: var(--white);
  width: fit-content;
  margin: 0 auto;
  padding: 10px 34px 10.2px;
  border-radius: 33.5px;
  font-family: "Josefin Sans", sans-serif;
  color: #333;
}

.bannerPage3 .right-container .designation-container .desigName {
  font-size: 20px;
  margin-bottom: 6px;
  font-weight: 600;
}

.bannerPage3 .right-container .designation-container .desigPara {
  font-size: 16px;
}

.bannerPage2 .designation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 6px 10.2px !important;
}

.bannerPage2 .designation-container .techStacks {
  border-radius: 20px;
  background-color: #f5f5f5;
  margin: 0 11px 0 11px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerPage2 .designation-container .moreTechStack {
  display: flex;
  justify-content: space-between;
  padding-right: 0;
  background-color: #3991a5;
  color: var(--white);
}

.bannerPage2 .designation-container .techStacks .techStackIcons {
  margin: 0 10px 0 0;
  font-size: 24px;
}

.bannerPage2 .designation-container .moreTechStack .techStackIcons {
  margin-left: 10px !important;
}

@media only screen and (max-width: 1100px) {
  .banner-container {
    height: 100%;
  }

  .bannerPage1,
  .bannerPage3 {
    padding-left: 50px;
  }

  .banner-content .main-heading {
    font-size: 38px;
    line-height: 50px;
    margin: 0 0 11.6px;
  }

  .bannerPage1 .left-container {
    max-width: 350px;
  }

  .bannerPage3 .left-container {
    max-width: 600px;
  }

  .bannerPage4 .left-container {
    max-width: 450px;
  }

  .banner-content .left-container p {
    font-size: 18px;
    line-height: 24px;
    margin: 11.6px 0 26.3px;
  }

  .banner-content .left-container button {
    width: fit-content;
    height: 38px;
    padding: 13px 21px 12.6px 22px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .bannerPage1,
  .bannerPage3 {
    padding-left: 35px;
  }

  .banner-content .main-heading {
    font-size: 32px;
    line-height: 40px;
  }

  .bannerPage1 .left-container {
    max-width: 340px;
  }

  .bannerPage2 .left-container,
  .bannerPage4 .left-container {
    margin: 0 auto 0 20px;
    min-width: 290px;
  }

  .banner-content .left-container p {
    font-size: 18px;
    line-height: 24px;
    margin: 8px 0 20px;
  }

  .banner-content .left-container button {
    width: fit-content;
    height: 35px;
    padding: 11px 16px 12.6px 15px;
    font-size: 16px;
  }

  .bannerPage1 .right-container {
    margin-left: 20px;
  }

  .bannerPage3 .right-container .designation-container {
    padding: 6px 25px;
    bottom: 20px;
  }

  .bannerPage3 .right-container .designation-container .desigName {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .bannerPage3 .right-container .designation-container .desigPara {
    font-size: 14px;
  }

  .bannerPage2 .right-container .designation-container {
    padding: 6px 8px 6px !important;
  }

  .bannerPage2 .designation-container .techStacks {
    font-size: 14px;
    margin: 0 6px 0 6px;
  }

  .bannerPage2 .designation-container .techStacks .techStackIcons {
    margin: 0 4px 0 0;
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {

  .banner-container .bannerPage2,
  .banner-container .bannerPage4 {
    flex-direction: column-reverse;
  }

  .banner-content {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
  }

  .banner-container .banner-content a {
    margin-bottom: 26px;
  }

  .bannerPage2 .left-container {
    max-width: 320px;
  }

  .bannerPage3 .left-container {
    max-width: 400px;
  }

  .banner-content .left-container {
    margin: 0 80px 0 0;
    width: 100%;
  }

  .bannerPage3 .left-container {
    margin: 0 40px 0 0;
  }

  .bannerPage4 .left-container {
    max-width: 400px;
    margin: 0 40px 0 0;
  }

  .banner-content .right-container {
    margin: 0 0 25px 0;
    width: 100%;
  }

  .bannerPage4 .right-container .rightContainer-InnerImg {
    right: 0;
  }

  .banner-content .right-container img {
    max-width: 550px;
    padding: 0 35px;
    height: 100%;
    margin: 0 auto;
  }

  .bannerPage2 .right-container .designation-container {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .banner-content .main-heading {
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.8px;
    margin: 24px 10px 11.1px 9.7px;
  }

  .banner-content .left-container {
    margin: 0;
    padding: 0 30px;
    max-width: 100%;
  }

  .banner-content .left-container p {
    margin: 0 10px 24px 9.7px;
    font-size: 16px;
    line-height: 22px;
    max-width: 300px;
  }

  .bannerPage3 .left-container p {
    margin: 0 10px 10px 9.7px;
  }

  .bannerPage2 .left-container ul {
    margin-left: 16px;
  }

  .banner-content .right-container {
    margin: 0 0 0 0;
    width: 100%;
  }

  .banner-content .left-container button {
    width: fit-content;
    height: 41px;
    /* margin: 0 49.1px 141.3px 9.7px; */
    padding: 12px 20.9px 12.6px 20px;
    font-size: 16px;
  }
}
