footer{
    background:var(--blue);
    color:var(--white);
    font-family: 'Josefin Sans';
}
.footer__row1{
    padding:3em 4em;
    display: flex;
    justify-content: space-between;
}

.footer__column1{
    flex:0.4;
    min-width:350px;
}

.footer__column2{
    flex:0.2;
}

.footer__column3{
    flex:0.2;
}

.footer__heading{
    font-size:35px;
    margin-bottom:0.8em;
    font-family: 'Passion One';
    letter-spacing: 0.5px;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.footer__content{
    font-size:16px;
    line-height:26px;
}

.footer__locate__row1,
.footer__locate__row2,
.footer__locate__row3
{
    margin-bottom:10px;
    display: flex;
}

.footer__row2{
    background:var(--darkBlue);
    text-align: center;
    padding:1em 0;
    font-size:18px;
}

.footer__row2 span{
    margin:0 3px;
}

.footer__locate__row1 img{
    width:15px;
    height:22px;
    margin-top:5px;
    margin-right:10px;
}

.footer__locate__row2 img{
    width:20px;
    height:16px;
    object-fit: contain;
    margin-right:10px;
    margin-top:5px;
}

.footer__locate__row3 img{
    width:20px;
    height:20px;
    margin-right:10px;
    margin-top:5px;
}

.footer__column3 .footer__content img{
    height:24px;
    width:24px;
}

.footer__column3 .footer__content a:not(:nth-child(3)) img{
    margin-right: 20px;
}

.location__text a{
    color:var(--white)
}

.motto{
    display: block;
    margin-bottom:10px;
    font-style: italic;
    font-size:18px;
    color:white;
}


@media screen and (max-width:920px){
    .footer__column1{
        min-width:unset;
    }
    .footer__row1{
        padding:3em 3em;
        display: flex;
        flex-direction: column;
    }
    .footer__column1,.footer__column2{
        margin-bottom:30px;
    }
    .footer__heading{
        margin-bottom:10px;
    }
}

@media screen and (max-width:768px){
    .footer__row1{
        padding:2em 1em;
    }
}

@media screen and  (max-width:480px){
    .arr{
        display: block;
        margin-top:4px;
    }
    .line{
        display: none;
    }
    .motto{
        font-size:16px;
    }
    .footer__heading{
        font-size: 25px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width:350px){
    .footer__row1{
        padding:25px 1em;
    }
}